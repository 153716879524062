import { useState } from 'react';
import Icons from '../components/Icons';
import { twMerge } from 'tailwind-merge';
import bg from '../assets/images/faq-bg.jpg';
import bgDark from '../assets/images/faq-dark-bg.jpg';
import Spline from '@splinetool/react-spline';
import { useSettings } from '../context/SettingsContext';

export const FAQ = () => {
  const { isDark } = useSettings();

  const [activeQ, setActiveQ] = useState<number | null>(null);

  const FAQs = [
    {
      question: 'Join people and create?',
      answer: 'This is the answer of the question',
    },
    {
      question: 'Wide range of media types?',
      answer: 'This is the answer of the question',
    },
    {
      question: 'Copy and Paste?',
      answer: 'This is the answer of the question',
    },
    {
      question: 'Future plans?',
      answer: 'This is the answer of the question',
    },
    {
      question: 'Price and Plans?',
      answer: 'This is the answer of the question',
    },
  ];

  return (
    <div
      id="FAQ"
      className="relative flex flex-col items-center justify-center"
    >
      <h2 className="text-[56px] mb-3 gradient-title">FAQs</h2>

      <div className="border border-gray-200 rounded-[15px] w-full max-w-[600px] bg-light/50 backdrop-blur-md">
        {FAQs.map((faq, i) => (
          <span key={'div' + i}>
            <div
              className="p-4 px-6 cursor-pointer"
              onClick={() => setActiveQ(activeQ === i ? null : i)}
            >
              <div className="flex flex-row justify-between items-center">
                <p className="text-lg font-semibold">{faq.question}</p>
                <Icons
                  name="chevron"
                  className={twMerge(
                    activeQ === i && 'rotate-180',
                    'transition duration-300'
                  )}
                />
              </div>
              <p
                className={twMerge(
                  activeQ === i ? 'block' : 'hidden',
                  'opacity-75 text-sm max-w-[400px]'
                )}
              >
                {faq.answer}
              </p>
            </div>
            {i !== FAQs.length - 1 && (
              <div className="bg-gray-200 w-full h-[1px]" />
            )}
          </span>
        ))}
      </div>
      <img
        src={isDark ? bgDark : bg}
        alt="bg"
        className="absolute z-[-2] w-[1000px] top-0"
      />
      {/* <Spline
        scene="https://prod.spline.design/SaZoI-Kd-4SWpQWl/scene.splinecode"
        className="absolute top-[-150px] left-0 w-full h-full z-[-1]"
      /> */}
    </div>
  );
};
