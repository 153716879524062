import { useEffect, useState } from "react";
import emailIcon from "../assets/icons/email.svg";
import axios from "axios";
import Icons from "../components/Icons";
import profileImage from "../assets/images/profile.jpg";
import profileDarkImage from "../assets/images/profile-dark.png";
import profilePhoneDarkImage from "../assets/images/profile-phone-dark.png";
import profilePhoneImage from "../assets/images/profile-phone.png";
import bg from "../assets/images/faq-bg.jpg";
import bgDark from "../assets/images/faq-dark-bg.jpg";
import { twMerge } from "tailwind-merge";
import { useModal } from "../context/ModalContext";
import { Img } from "../components/Img/Img";
import { AnimatePresence, motion } from "framer-motion";
import Spline from "@splinetool/react-spline";
import { useSettings } from "../context/SettingsContext";
import { Link } from "react-router-dom";

const Hero = () => {
  const { setModal, setReferral } = useModal();
  const { isDark } = useSettings();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [isLoaded, setIsLoaded] = useState(true);

  // no scroll while loading
  useEffect(() => {
    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isLoaded]);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage("");
    const api_url = "https://api.getwaitlist.com/api/v1/waiter";
    const isRefferd = window.location.search.includes("ref_id");
    const urlParams = new URLSearchParams(window.location.search);
    const ref_id = urlParams.get("ref_id");

    let data: any = {
      email,
      waitlist_id: 12101,
    };

    if (isRefferd) {
      data = {
        ...data,
        referral_link: "https://www.colcolcol.co/?ref_id=" + ref_id,
      };
    }

    axios
      .post(api_url, data)
      .then((res) => {
        setReferral(res.data.referral_link);
        setModal("waitlist");
        setLoading(false);
        setEmail("");
      })
      .catch((err) => {
        setErrorMessage(err.response.data.error_string);
        setLoading(false);
      });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
      e.target.blur();
    }
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const animationLoaded = () => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
  };

  return (
    <div
      id="Hero"
      className="flex flex-col gap-6 justify-center items-center mt-[100px]"
    >
      <AnimatePresence>
        {!isLoaded && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="fixed top-0 left-0 flex items-end bg-light w-full h-full z-[1000] p-[50px]"
          >
            <div className="flickering flex flex-row justify-between w-full">
              <Icons name="logo" className="w-[16px]" />
              <p className="font-semibold text-[#fff]">COL</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Img
        src={bg}
        alt="bg"
        className={twMerge(
          isDark ? "invisible" : "visible",
          "absolute z-[-2] w-[1000px] top-0"
        )}
      />

      <Img
        src={bgDark}
        alt="bg"
        className={twMerge(
          isDark ? "visible" : "invisible",
          "absolute z-[-2] w-[1000px] top-0"
        )}
      />

      <div className="absolute z-[-1] w-full h-[300px] top-[53px] max-w-[300px]">
        <motion.svg
          initial={{ opacity: 0, scale: 0.1, y: -200 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewBox="0 0 301 257"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="301" height="257" fill="url(#paint0_radial_613_2765)" />
          <defs>
            <radialGradient
              id="paint0_radial_613_2765"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(150.326 8.80847) rotate(90) scale(98.9657 168.731)"
            >
              <stop stopColor="#4542DC" stopOpacity="0.32" />
              <stop
                offset="1"
                stopColor={isDark ? "#111111" : "white"}
                stopOpacity="0"
              />
            </radialGradient>
          </defs>
        </motion.svg>
      </div>
      <div>
        <div className={"text-[44px] md:text-[56px] text-center max-w-[800px]"}>
          <motion.span
            initial={{ opacity: 0, y: 40, filter: "blur(10px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            transition={{
              delay: 1,
              duration: 0.5,
              type: "tween",
              stiffness: 100,
            }}
            className="text-dark font-semibold gradient-title"
          >
            Discover
          </motion.span>
          <motion.span
            initial={{ opacity: 0, y: 40, filter: "blur(10px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            transition={{
              delay: 1.5,
              duration: 0.5,
              type: "tween",
              stiffness: 100,
            }}
            className="text-dark font-semibold gradient-title"
          >
            , Collect,
          </motion.span>{" "}
          <motion.span
            initial={{ opacity: 0, y: 40, filter: "blur(10px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            transition={{
              delay: 1.7,
              duration: 0.5,
              type: "tween",
              stiffness: 100,
            }}
            className="text-dark font-semibold gradient-title"
          >
            and Share
          </motion.span>{" "}
          <motion.span
            initial={{ opacity: 0, y: 40, filter: "blur(10px)" }}
            animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
            transition={{
              delay: 2.1,
              duration: 0.5,
              type: "tween",
              stiffness: 100,
            }}
            className="text-dark font-semibold gradient-title"
          >
            with COL
          </motion.span>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.8,
          duration: 0.5,
          type: "tween",
          stiffness: 100,
        }}
      >
        <div className="relative w-[350px] md:w-[410px] m-auto">
          <span
            className={twMerge(
              email !== "" ? "opacity-100" : "opacity-50",
              "transition duration-300"
            )}
            style={{ position: "absolute", left: "15px", top: "14px" }}
            onClick={handleSubmit}
          >
            <Icons name="email" />
          </span>

          <input
            type="text"
            className="input input-icon font-medium"
            placeholder="Enter email to join the waitlist"
            value={email}
            onKeyDown={handleKeyPress}
            onChange={handleEmail}
          />
          <span
            className="cursor-pointer hover:opacity-50 transition duration-300"
            style={{ position: "absolute", right: "15px", top: "10px" }}
            onClick={handleSubmit}
          >
            <Icons name="enter" />
          </span>
        </div>
        {errorMessage && (
          <p className="text-sm text-center mt-3 font-bold text-red-600">
            {errorMessage}
          </p>
        )}
      </motion.div>

      {/* <Spline
        scene="https://prod.spline.design/VKU6eAF4GowZqfeT/scene.splinecode"
        style={isLoaded ? { opacity: 1 } : { opacity: 0 }}
        className="absolute top-0 left-0 w-full h-full z-[-1] transition-opacity duration-300"
        onLoad={() => animationLoaded()}
      /> */}

      <div className="relative w-full h-full min-h-[50vh] md:min-h-[75vh]">
        <Img
          initial={{ y: 40 }}
          animate={{ y: 0 }}
          transition={{ delay: 1.3 }}
          src={profileImage}
          className={twMerge(
            isDark ? "invisible" : "invisible sm:visible",
            "w-full absolute top-0 left-0"
          )}
        />
        <Img
          initial={{ y: 40 }}
          animate={{ y: 0 }}
          transition={{ delay: 1.3 }}
          src={profileDarkImage}
          className={twMerge(
            isDark ? "visible" : "invisible sm:invisible",
            "w-full absolute top-0 left-0 "
          )}
        />

        <Img
          src={profilePhoneImage}
          className={twMerge(
            isDark ? "invisible" : "visible sm:invisible",
            "w-full absolute top-0 left-0"
          )}
        />

        <Img
          src={profilePhoneDarkImage}
          className={twMerge(
            isDark ? "visible sm:invisible" : "invisible",
            "w-full absolute top-0 left-0"
          )}
        />
      </div>
    </div>
  );
};

export default Hero;
