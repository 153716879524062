import { motion } from 'framer-motion';
import Icons from '../Icons';

export const Color = ({ size, item, isMobile }: any) => {
  const sizes: any = {
    s: {
      container: {
        width: item ? '100%' : isMobile ? '300px' : '400px',
        height: '80px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        borderRadius: '12px',
        padding: '10px 15px',
        paddingBottom: '10px',
      },
      color: {
        minWidth: '50px',
        height: '50px',
        borderRadius: '30px',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
      },
      text: {},
    },
    m: {
      container: {
        width: item ? '100%' : isMobile ? '300px' : '400px',
        height: '150px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        borderRadius: '10px',
        padding: item ? '5px' : '0px 0px',
        paddingBottom: item ? '5px' : '0px',
      },
      color: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: item ? '100%' : isMobile ? '290px' : '390px',
        height: item ? '100%' : '140px',
        borderRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      text: {},
    },
    l: {
      container: {
        width: item ? '100%' : isMobile ? '300px' : '400px',
        height: isMobile ? '300px' : '332px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        borderRadius: '10px',
        padding: '5px',
        paddingBottom: '15px',
      },
      color: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: item ? '100%' : isMobile ? '290px' : '390px',
        height: '290px',
        borderRadius: '8px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      text: {},
    },
  };
  return (
    <motion.div
      initial={{
        ...sizes[size].container,
        opacity: 0,
        transform: 'translatey(20px)',
      }}
      animate={{
        ...sizes[size].container,
        opacity: 1,
        transform: 'translatey(0px)',
      }}
      transition={{ type: 'tween', stiffness: 100 }}
      className="shadow-sm border border-gray-300 bg-light hover:border-[#ADBAFF] duration-300"
      style={{ transitionProperty: 'border' }}
    >
      <motion.div
        initial={{
          ...sizes[size].color,
          opacity: 0,
          transform: 'translatey(20px)',
        }}
        animate={{
          ...sizes[size].color,
          opacity: 1,
          transform: 'translatey(0px)',
        }}
        transition={{ type: 'tween', stiffness: 100 }}
        style={{ backgroundColor: item?.hex ?? (size === 's' ? 'rgba(89, 33, 23, 0.25)' : '#592117') }}
        className="relative flex justify-center items-center"
      >
        {size === 'm' && (
          <>
            <motion.p
              initial={{
                ...sizes[size].text,
                opacity: 0,
                transform: item ? 'translatey(20px)' : 'translatex(-20px)',
              }}
              animate={{
                ...sizes[size].text,
                opacity: 0.5,
                transform: item ? 'translatey(0px)' : 'translatex(0px)',
              }}
              transition={{ type: 'tween', stiffness: 100, delay: 0.3 }}
              className="text-sm font-medium text-white"
            >
              {item?.hex ?? '#592117'}
            </motion.p>
            <motion.div
              initial={{
                ...sizes[size].text,
                opacity: 0,
                transform: item ? 'translatey(20px)' : 'translatex(-20px)',
              }}
              animate={{
                ...sizes[size].text,
                opacity: 0.5,
                transform: item ? 'translatey(0px)' : 'translatex(0px)',
              }}
              transition={{ type: 'tween', stiffness: 100, delay: 0.5 }}
              className="absolute top-[20px] right-[20px]"
            >
              <Icons name="copy" fill={'rgba(255, 255, 255)'} className='opacity-70 hover:opacity-100 transition-opacity duration-300 cursor-pointer' />
            </motion.div>
          </>
        )}
        {size === 's' && (
          <div
            className={'w-[43px] h-[43px] rounded-full m-auto'}
            style={{ backgroundColor: item?.hex ?? '#592117' }}
          />
        )}
      </motion.div>

      {size !== 'm' && (
        <div className="w-full flex flex-row justify-between items-center pl-3 pr-6">
          <motion.p
            initial={{
              ...sizes[size].text,
              opacity: 0,
              transform: item ? 'translatey(20px)' : 'translatex(-20px)',
            }}
            animate={{
              ...sizes[size].text,
              opacity: 0.5,
              transform: item ? 'translatey(0px)' : 'translatex(0px)',
            }}
            transition={{ type: 'tween', stiffness: 100, delay: 0.3 }}
            className="text-sm font-medium"
          >
            #592117
          </motion.p>

          <span className="opacity-50">
            <Icons name="copy" />
          </span>
        </div>
      )}
    </motion.div>
  );
};
