import bg from '../assets/images/collection-bg.jpg';
import bgDark from '../assets/images/collection-bg-dark.png';
import col from '../assets/images/collection.png';
import colDark from '../assets/images/collection-dark.png';
import Icons from '../components/Icons';
import { useSettings } from '../context/SettingsContext';

const Collection = () => {
  const {isDark} = useSettings();
  
  return (
    <div id="Collection">
      <div className="flex flex-row gap-1 items-center p-3">
      <Icons name="logo" className='cursor-pointer w-[16px] h-[16px]' />
        <p className="font-bold">COL</p>
      </div>
      <div className="relative">
        <div className="w-full h-[1px] bg-gray-200 my-6" />

        <div className="flex flex-col gap-3 md:flex-row md:items-center justify-between p-3 pb-0">
          <h1 className="text-[44px] sm:text-[56px] gradient-title">Collection</h1>

          <p className="md:text-[18px] max-w-[600px] opacity-75">
          Explore and connect with fellow creators on COL. With features like Creator of the Day, Week, and Month, we spotlight inspiring talents, encouraging a vibrant community of shared creativity.
          </p>

          <img
            src={isDark ? bgDark : bg}
            alt="bg"
            className="absolute z-[-1] w-[600px] top-0 right-0"
          />
        </div>

        <img src={isDark ? colDark : col} alt="col" className="w-full mt-6" />
      </div>
    </div>
  );
};

export default Collection;
