import React, { createContext, useContext, useEffect, useState } from 'react';

export const SettingsContext = createContext<any>(undefined);

export const useSettings = () => {
  return useContext(SettingsContext);
};

export const SettingsContextProvider = ({
  children,
}: {
  children: React.ReactElement<any | any>;
}) => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDark]);

  const value = {
    isDark,
    setIsDark,
  };

  return (
    <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
  );
};
