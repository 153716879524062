import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Img } from '../Img/Img';

const Image = ({ size, item, isMobile }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded1, setIsLoaded1] = useState(false);

  const sizes: any = {
    s: {
      container: {
        height: item ? '100%' : isMobile ? '200px' : '300px',
        width: item ? '100%' : isMobile ? '300px' : '400px',
        padding: isMobile ? '2px' : '5px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
      },
      img: {
        height: item ? '100%' : isMobile ? '200px' : '300px',
        width: item ? '100%' : isMobile ? '300px' : '400px',
        borderRadius: '9px',
        borderBottomLeftRadius: '9px',
        borderBottomRightRadius: '9px',
      },
      img2: {
        height: '100px',
        width: '200px',
        borderRadius: '8px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      img3: {
        height: '100px',
        width: '100px',
        borderRadius: '8px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
    },
    m: {
      container: {
        width: item ? '100%' : '300px',
        height: item ? '100%' : '300px',
        padding: isMobile ? '2px' : '5px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
      },
      img: {
        height: '175px',
        width: item ? '100%' : '300px',
        borderRadius: '8px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      img2: {
        height: '125px',
        width: item ? '100%' : '150px',
        borderRadius: '9px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      img3: {
        height: '125px',
        width: item ? '100%' : '150px',
        borderRadius: '9px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
    },
    l: {
      container: {
        height: item ? '100%' : isMobile ? '200px' : '250px',
        width: item ? '100%' : isMobile ? '300px' : '400px',
        padding: isMobile ? '2px' : '5px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
      },
      img: {
        height: item ? '100%' : isMobile ? '200px' : '250px',
        width: item ? '100%' : isMobile ? '300px' : '400px',
        borderRadius: '9px',
        borderBottomLeftRadius: '9px',
        borderBottomRightRadius: '9px',
      },
      img2: {
        height: '125px',
        width: '150px',
        borderRadius: '9px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      img3: {
        height: '125px',
        width: '150px',
        borderRadius: '9px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
    },
  };

  return (
    <motion.div
      initial={{
        ...sizes[size].container,
        opacity: 0,
        transform: 'translatey(20px)',
      }}
      animate={{
        ...sizes[size].container,
        opacity: 1,
        transform: 'translatey(0px)',
      }}
      transition={{ type: 'tween', stiffness: 100 }}
      className={
        'bg-light shadow-sm border border-gray-300 hover:border-[#ADBAFF] cursor-pointer duration-300'
      }
      style={{ transitionProperty: 'border' }}
    >
      {size !== 'l' && (
        <motion.img
          initial={{ ...sizes[size].img, filter: 'blur(10px)' }}
          animate={{ ...sizes[size].img, filter: 'blur(0px)'}}
          transition={{ type: 'tween', stiffness: 100 }}
          src={
            (item?.thumbnail || item?.data[0]?.thumbnail) ??
            'https://assets.vogue.com/photos/63ebd5a25eabdb09db71195c/master/w_2240,c_limit/47610_PAL_Vogue_025_05.jpg'
          }
          loading="lazy"
          style={{ opacity: isLoaded ? '1' : '0', transition: 'opacity 0.3s' }}
          onLoad={() => {setIsLoaded1(false); setIsLoaded(true)}}
          alt="logo"
          className="overflow-hidden object-cover border border-light"
        />
      )}
        {size === 'm' && (
          <div className="flex flex-row">
            <motion.img
              initial={{
                ...sizes[size].img2,
                opacity: 0,
                transform: 'translatey(20px)',
              }}
              animate={{
                ...sizes[size].img2,
                opacity: 1,
                transform: 'translatey(0px)',
              }}
              transition={{ type: 'tween', stiffness: 100, delay: 0.1 }}
              src={
                item?.data[1]?.thumbnail ??
                'https://i.pinimg.com/474x/c4/76/9a/c4769a44746de9dead72a7f5ebf68af8.jpg'
              }
              alt="logo"
              className="overflow-hidden object-cover border border-light"
            />
            <motion.img
              initial={{
                ...sizes[size].img3,
                opacity: 0,
                transform: 'translatey(20px)',
              }}
              animate={{
                ...sizes[size].img3,
                opacity: 1,
                transform: 'translatey(0px)',
              }}
              transition={{ type: 'tween', stiffness: 100 }}
              src={
                item?.data[2]?.thumbnail ??
                'https://i.pinimg.com/474x/5b/b3/fe/5bb3fea37cec53ecd06b46149cc9964f.jpg'
              }
              alt="logo"
              className="overflow-hidden object-cover border border-light"
            />
          </div>
        )}

      {size === 'l' && (
       
        <motion.img
          initial={{ ...sizes[size].img }}
          animate={{ ...sizes[size].img }}
          transition={{ type: 'tween', stiffness: 100 }}
          src={
            (item?.thumbnail || item?.data[0]?.thumbnail) ??
            'https://i.pinimg.com/originals/0c/7b/7b/0c7b7bd6de1525cecb762d4f3de34ea1.gif'
          }
          loading="lazy"
          style={{ opacity: isLoaded1 ? '1' : '0', transition: 'opacity 0.3s' }}
          onLoad={() => {setIsLoaded1(true); setIsLoaded(false)}}
          alt="logo"
          className="overflow-hidden object-cover border border-light"
        />
      )}
    </motion.div>
  );
};

export default Image;
