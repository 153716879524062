export const USERS = [
  {
    id: 0,
    name: 'Paco Coursey',
    avatar:
      'https://i.pinimg.com/474x/6e/c3/ac/6ec3acfc53f5d1146c59a067d79a2d87.jpg',
    title: 'Drawing',
    totalItems: '129',
    thumbnail:
      'https://i.pinimg.com/474x/eb/72/b6/eb72b6f57f7ea7879ee00f017cc88c0e.jpg',
  },
  {
    id: 1,
    name: 'Marcel',
    avatar:
      'https://i.pinimg.com/474x/6e/c3/ac/6ec3acfc53f5d1146c59a067d79a2d87.jpg',
    title: 'Architecture',
    totalItems: '624',
    thumbnail:
      'https://images.squarespace-cdn.com/content/v1/5ddaa3bc38a66758d7a9e9e0/1668955205793-JXVWMBQEP3TSLQUME576/download+%284%29.jpeg?format=2500w',
  },
  {
    id: 2,
    name: 'Yuhang',
    avatar:
      'https://i.pinimg.com/474x/6e/c3/ac/6ec3acfc53f5d1146c59a067d79a2d87.jpg',
    title: 'Branding',
    totalItems: '1 204',
    thumbnail:
      'https://cdn.dribbble.com/users/1358460/screenshots/7563193/media/d06c8fc07de3f0fc1cf3d06aa834baa0.jpg?resize=1600x1200&vertical=center',
  },
];

export const COLLECTION = [
  // marcel
  {
    user: USERS[0],
    id: 0,
    data: [
      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/eb/72/b6/eb72b6f57f7ea7879ee00f017cc88c0e.jpg',
      },
      {
        type: 'Embeds',
        size: 'm',
        src: 'https://open.spotify.com/embed/track/2O6bWtHJgPuig3EQVVZpRl?utm_source=generator',
      },
      {
        type: 'image-gallery',
        data: [
          {
            thumbnail:
              'https://i.pinimg.com/474x/dc/77/93/dc7793f9d2073d83053b5b5c7284fe97.jpg',
            alt: 'illustration',
          },
          {
            thumbnail:
              'https://i.pinimg.com/474x/5a/c7/c5/5ac7c5e52a70bdfe483c7c0f508582b8.jpg',
            alt: 'illustration',
          },
          {
            thumbnail:
              'https://i.pinimg.com/474x/c1/96/1a/c1961a038072dc721453cd76d5e8b5d7.jpg',
            alt: 'illustration',
          },
        ],
      },
      {
        type: 'Colors',
        hex: '#C84225',
        size: 'm',
      },

      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/564x/f4/bc/74/f4bc7460d4ae1343146ce899e110d07a.jpg',
      },
      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/9f/89/60/9f8960d5d28b5a47f6c18a0954e331a8.jpg',
      },

      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/f5/5c/e3/f55ce35a4110ab61ce1aa9c441ec3693.jpg',
      },

      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/c6/da/3f/c6da3f5b7037c81b50856922b2d1ec75.jpg',
      },
    ],
  },

  {
    user: USERS[1],
    id: 1,
    data: [
      {
        type: 'Image',
        thumbnail:
          'https://www.hanalink.co/static/media/b1.23b253bec07ea1065bdc.gif',
      },

      {
        type: 'checkbox',
        data: [
          { task: 'Create a new project', completed: true },
          { task: 'Create a new project', completed: false },
          { task: 'Create a new project', completed: false },
          { task: 'Create a new project', completed: false },
        ],
      },
      {
        type: 'list-link',
        data: [
          {
            title: 'Arch Daily',
            desc: 'https://www.archdaily.com',
            thumbnail:
              'https://assets.adsttc.com/doodles/flat/logo-blue-full.svg',
            link: 'https://www.archdaily.com/',
            size: 'm',
          },
          {
            title: 'Dezeen',
            desc: 'https://www.dezeen.com',
            thumbnail:
              'https://static.dezeen.com/uploads/2011/07/dezeen_dezeen-1.gif',
            link: 'https://www.google.com',
            size: 'm',
          },
        ],
      },
      {
        type: 'Text',
        thumbnail:
          'https://i.pinimg.com/474x/d6/8d/3a/d68d3ab1a7a7e1803b294ccf67892b7d.jpg',
      },
      {
        type: 'Colors',
        hex: '#95936E',
        size: 'm',
      },
      {
        type: 'Colors',
        hex: '#30241B',
        size: 'm',
      },
      {
        type: 'Image',
        thumbnail:
          'https://www.home-designing.com/wp-content/uploads/2019/05/modern-sofa-1.jpg',
      },
      {
        type: 'Image',
        thumbnail:
          'https://images.adsttc.com/media/images/6391/9a01/4a44/6025/7dc5/be92/newsletter/10-interior-design-trends-of-2022_44.jpg?1670486535',
      },

      {
        type: 'image-gallery',
        data: [
          {
            thumbnail:
              'https://i.pinimg.com/564x/a0/89/9e/a0899e5e5553f0af60d5bb3394856a0a.jpg',
            alt: 'illustration',
          },
          {
            thumbnail:
              'https://i.pinimg.com/474x/e1/9e/71/e19e7126c0dbb08bb27396945ff85912.jpg',
            alt: 'illustration',
          },
          {
            thumbnail:
              'https://i.pinimg.com/474x/a2/30/b4/a230b4cc7fe673e66c73062f860abf50.jpg',
            alt: 'illustration',
          },
        ],
      },

      {
        type: 'Colors',
        hex: '#B3A494',
        size: 'm',
      },
      {
        type: 'Image',
        thumbnail:
          'https://images.squarespace-cdn.com/content/v1/5ddaa3bc38a66758d7a9e9e0/1668955205793-JXVWMBQEP3TSLQUME576/download+%284%29.jpeg?format=2500w',
      },

      {
        type: 'Image',
        thumbnail:
          'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/997714134804849.61dca9990ea9e.jpg',
      },
    ],
  },

  // Yuhang
  {
    user: USERS[2],
    id: 2,
    data: [
      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/6e/c3/ac/6ec3acfc53f5d1146c59a067d79a2d87.jpg',
      },
      {
        type: 'image-gallery',
        data: [
          {
            thumbnail:
              'https://i.pinimg.com/474x/76/22/70/762270912e77978bc540efe22055f38e.jpg',
            alt: 'illustration',
          },
          {
            thumbnail:
              'https://i.pinimg.com/474x/21/29/f6/2129f63272f1c355fc31bc1ee53debe6.jpg',
            alt: 'illustration',
          },
          {
            thumbnail:
              'https://i.pinimg.com/474x/6a/6e/27/6a6e271224f4f1832f4734927108dd1e.jpg',
            alt: 'illustration',
          },
        ],
      },
      {
        type: 'Colors',
        hex: '#586E52',
        size: 'm',
      },
      {
        type: 'Text',
        thumbnail:
          'https://i.pinimg.com/474x/d6/8d/3a/d68d3ab1a7a7e1803b294ccf67892b7d.jpg',
      },
      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/33/2a/65/332a652879a17afdbe221d3dcf66ef73.jpg',
      },
      {
        type: 'Image',
        thumbnail:
          'https://cdn.dribbble.com/users/1358460/screenshots/7563193/media/d06c8fc07de3f0fc1cf3d06aa834baa0.jpg?resize=1600x1200&vertical=center',
      },
      {
        type: 'checkbox',
        data: [
          { task: 'Create a new project', completed: true },
          { task: 'Create a new project', completed: false },
          { task: 'Create a new project', completed: false },
          { task: 'Create a new project', completed: false },
        ],
      },
      {
        type: 'Image',
        thumbnail:
          'https://i.pinimg.com/474x/ff/87/c5/ff87c549a41ecd58c04982a7d4b54306.jpg',
      },
    ],
  },
];

export const DANIEL_COLLECTION = [
  {
    id: 0,
    thumbnail:
      'https://images.squarespace-cdn.com/content/v1/5ddaa3bc38a66758d7a9e9e0/1668955205793-JXVWMBQEP3TSLQUME576/download+%284%29.jpeg?format=2500w',
    title: 'Architecture',
    totalItems: 63,
  },
  {
    id: 1,
    thumbnail:
      'https://images.adsttc.com/media/images/6391/9a01/4a44/6025/7dc5/be92/newsletter/10-interior-design-trends-of-2022_44.jpg?1670486535',
    title: 'Illustrations',
    totalItems: 292,
  },
  {
    id: 2,
    thumbnail:
      'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7af740184533691.6553b25c27a35.gif',
    title: 'Colors',
    totalItems: 10,
  },
  {
    id: 3,
    thumbnail:
      'https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/e53049183553015.6541f0c7a712a.png',
    title: 'Videos',
    totalItems: 3,
  },
];

export const LAST_COLLECTION = [
  {
    id: 0,
    thumbnail:
      'https://assets.vogue.com/photos/63ebd5a25eabdb09db71195c/master/w_2240,c_limit/47610_PAL_Vogue_025_05.jpg',
    title: 'Photography',
    totalItems: 63,
  },
  {
    id: 1,
    thumbnail:
      'https://i.pinimg.com/564x/f5/5c/e3/f55ce35a4110ab61ce1aa9c441ec3693.jpg',
    title: 'Drawing',
    totalItems: 10,
  },
  {
    id: 2,
    thumbnail:
      'https://i.pinimg.com/564x/60/b1/21/60b1210e17737ae897ac1df335b45f8f.jpg',
    title: '3D',
    totalItems: 86,
  },
];

export const LAST = {
  username: 'abdirahim',
  occupation: 'Photographer',
  firstname: 'Abdirahim',
  lastname: 'Arrale',
  email: '',
  collections: LAST_COLLECTION,
};
