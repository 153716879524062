
import Icons from '../components/Icons';
import { useSettings } from '../context/SettingsContext';

const Footer = () => {
  const {isDark} = useSettings();
  
  return (
    <div className="relative flex flex-col gap-3 items-center justify-center border-t border-gray-200 py-[20px] sm:p-[50px] overflow-hidden">
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0"
      >
        <rect width="100%" height="100%" fill="url(#paint0_radial_613_2765)" />
        <defs>
          <radialGradient
            id="paint0_radial_613_2765"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(150.326 8.80847) rotate(90) scale(98.9657 168.731)"
          >
            <stop stopColor="#4542DC" stopOpacity="0.32" />
            <stop
              offset="1"
              stopColor={isDark ? '#111111' : 'white'}
              stopOpacity="0"
            />
          </radialGradient>
        </defs>
      </svg>

      <div className="flex flex-row gap-1 items-center">
        <Icons name="logo" className="cursor-pointer w-[16px] h-[16px]" />
        <p className="font-bold">COL</p>
      </div>

      {/* <ul className="flex flex-row gap-6">
        <li className="text-sm md:text-base opacity-50 hover:opacity-100 transition duration-300 font-bold cursor-pointer">
          Terms and Conditions
        </li>
        <li className="text-sm md:text-base opacity-50 hover:opacity-100 transition duration-300 font-bold cursor-pointer">
          Privacy Policy
        </li>
        <li className="text-sm md:text-base opacity-50 hover:opacity-100 transition duration-300 font-bold cursor-pointer">
          Manifesto
        </li>
      </ul> */}

      <div className="flex flex-row gap-6 ">
        <Icons
          name="twitter"
          className="opacity-25 hover:opacity-100 transition duration-300 cursor-pointer"
        />
        <Icons
          name="instagram"
          className="opacity-25 hover:opacity-100 transition duration-300 cursor-pointer"
        />
       
      </div>
    </div>
  );
};

export default Footer;
