import { motion } from 'framer-motion';
import { useState } from 'react';

export const Img = ({
  src,
  className,
  alt,
  initial,
  animate,
  transition,
}: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <motion.img
      initial={{ opacity: 0, ...initial }}
      animate={{ opacity: 1, ...animate }}
      transition={{ type: 'tween', stiffness: 100, ...transition }}
      src={src}
      className={className}
      loading="lazy"
      style={{ opacity: isLoaded ? '1' : '0', transition: 'opacity 0.3s' }}
      onLoad={() => setIsLoaded(true)}
      alt={alt ?? 'illustration'}
    />
  );
};
