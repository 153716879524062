import { motion } from "framer-motion";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

export const Video = ({ item, isMobile }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <motion.div
      initial={{
        filter: "blur(10px)",
        opacity: 0,
        transform: "translatey(20px)",
      }}
      animate={{
        filter: "blur(0px)",
        opacity: 1,
        transform: "translatey(0px)",
      }}
      transition={{ type: "tween", stiffness: 100 }}
      className={twMerge(
        item ? "w-full" : "w-full sm:w-[400px]",
        "bg-light p-1 shadow-sm rounded-sm border border-gray-300 hover:border-[#ADBAFF] cursor-pointer transition-all duration-300"
      )}
    >
      <iframe
        title={"video"}
        src={
          "https://www.youtube.com/embed/Gse0dDVxU3I?controls=0&rel=0&disablekb=1&showinfo=0&modestbranding=0&html5=1&iv_load_policy=3&autoplay=0&end=0&loop=1&playsinline=0&start=0&nocookie=false&playlist=Gse0dDVxU3I&enablejsapi=1&origin=https%3A%2F%2Fwww.vimomedia.com&widgetid=1"
        }
        className="rounded-[9px] h-52  w-full"
        style={{
          opacity: isLoaded ? "1" : "0",
          transition: "opacity 0.3s",
        }}
        onLoad={() => setIsLoaded(true)}
      ></iframe>{" "}
    </motion.div>
  );
};
