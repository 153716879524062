import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth';

const app = firebase.initializeApp({
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	projectId: process.env.REACT_APP_PROJECTID,

	storageBucket: process.env.REACT_APP_STORAGEBUCK,
	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_APPID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

export const auth = getAuth(app);
export default app;
