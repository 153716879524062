
import ai from '../assets/images/ai.jpg';
import aiDark from '../assets/images/ai-dark.png';
import aiPhoneDark from '../assets/images/ai-phone-dark.png';
import aiPhone from '../assets/images/ai-phone.png';
import { useSettings } from '../context/SettingsContext';

const AI = () => {
  const {isDark} = useSettings();
  
  return (
    <div id="AI" className="flex flex-col items-center p-3">
      <div>
        <h2 className="text-[44px] sm:text-[56px] text-center gradient-title mb-3 md:mb-0"> AI Powered tagging </h2>
        <p className='text-center opacity-75 m-auto max-w-[600px]'>
        Our AI-tagging transforms how you search for inspiration. Struggling to find the right word or color? With COL, discover the exact vibe you're seeking, effortlessly.

        </p>
      </div>
      <div className='relative min-h-[70vh] md:min-h-[75vh] w-full'>

      <img src={isDark ? aiDark : ai} className="invisible sm:visible w-full mt-6 absolute top-0 left-0" />
      <img src={isDark ? aiPhoneDark : aiPhone} className="visible sm:invisible w-full mt-6 absolute top-0 left-0" />
      </div>
    </div>
  );
};

export default AI;
