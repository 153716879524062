// public pages
import { Col } from "../pages/Col";
import { Landing } from "../pages/Landing";
import Profile from "../pages/Profile";
import SignUp from "../pages/SignUp";


const publicRoutes = [
  { path: '*', component: <Landing /> },
  // { path: '/:userId', component: <Profile />},
  // { path: '/:userId/:colId', component: <Col />},
  // { path: '/auth', component: <SignUp />},
];

export { publicRoutes };
