import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import Waitlist from '../components/Waitlist/Waitlist'

interface valueType {
  modal: 'menu' | 'shot' | 'edit-profile' | 'upload-shot' | 'case' | 'waitlist' | undefined
  setModal: Dispatch<SetStateAction<'menu' | 'shot' | 'edit-profile' | 'upload-shot' | 'case' | 'waitlist' | undefined>>
  id: number | undefined
  setId: Dispatch<SetStateAction<number | undefined>>
  referral: string | undefined
  setReferral: Dispatch<SetStateAction<string | undefined>>
}

export const ModalContext = createContext<any>(undefined)

export const useModal = () => {
  return useContext(ModalContext)
}

export const ModalContextProvider = ({ children }: { children: React.ReactElement<any | any> }) => {
  const [modal, setModal] = useState<
    'menu' | 'shot' | 'edit-profile' | 'upload-shot' | 'case' | 'waitlist' | undefined
  >()
  const [id, setId] = useState<number | undefined>(undefined)
  const [referral, setReferral] = useState<string | undefined>(undefined)

  const value: valueType = {
    modal,
    setModal,
    setId,
    id,
    referral,
    setReferral,
  }

  return (
    <ModalContext.Provider value={value}>
      <AnimatePresence>{modal === 'waitlist' && <Waitlist />}</AnimatePresence>
      {children}
    </ModalContext.Provider>
  )
}
