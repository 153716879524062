import { motion } from 'framer-motion';
import Icons from '../Icons';
import { twMerge } from 'tailwind-merge';

export const Text = ({ size, item, isMobile }: any) => {
  const sizes: any = {
    s: {
      container: {
        width: item ? '100%' : isMobile ? '300px' : '400px',
        height: item ? '100%' : isMobile ? '150px' : '94px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        borderRadius: '12px',
        padding: '10px 15px',
      },
      text: {},
    },
    m: {
      container: {
        width: item ? '100%' : isMobile ? '300px' : '400px',
        height: item ? '100%' : isMobile ? '120px' : '150px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        borderRadius: '10px',
        padding: '10px 15px',
      },
      text: {},
    },
    l: {
      container: {
        width: item ? '100%' : isMobile ? '300px' : '400px',
        height: item ? '100%' : isMobile ? '230px' : '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        borderRadius: '10px',
        padding: '5px',
      },
      text: {},
    },
  };
  return (
    <motion.div
      initial={{
        filter: 'blur(10px)',
        ...sizes[size].container,
        opacity: 0,
        transform: 'translatey(20px)',
      }}
      animate={{
        filter: 'blur(0px)',
        ...sizes[size].container,
        opacity: 1,
        transform: 'translatey(0px)',
      }}
      transition={{ type: 'tween', stiffness: 100 }}
      className={twMerge( size === 'l' ? 'border-[#B1B4E9] bg-[#FBFBFE]' : 'border-gray-300 bg-light' , "shadow-sm border")}
    >
      {size === 's' && (
        <motion.p
          initial={{
            opacity: 0,
            transform: item ? 'translatey(0px)' : 'translatex(-20px)',
          }}
          animate={{
            opacity: 1,
            transform: item ? 'translatey(0px)' : 'translatex(0px)',
          }}
          transition={{ type: 'tween', stiffness: 100, delay: 0.3 }}
          className="text-gray-500"
        >
          We are excited to share a few more layouts from one of our latest
          projects for. to share a few more layouts from one of our latest
          projects for
        </motion.p>
      )}

      {size === 'm' && (
        <motion.div
          initial={{
            opacity: 0,
            transform: item ? 'translatey(0px)' : 'translatex(-20px)',
          }}
          animate={{
            opacity: 1,
            transform: item ? 'translatey(0px)' : 'translatex(0px)',
          }}
          transition={{ type: 'tween', stiffness: 100, delay: 0.3 }}
          className="flex flex-col gap-2 items-center"
        >
          <label className="container">
            <input type="checkbox" onChange={() => false} checked={true} />
            <span className="checkmark"></span>
            <p className="text-xs sm:text-base">Add the new version</p>
          </label>
          <label className="container">
            <input type="checkbox" onChange={() => false} />
            <span className="checkmark"></span>
            <p className="text-xs sm:text-base">
              Guiltlessly exploring passion and interests
            </p>
          </label>
          <label className="container">
            <input type="checkbox" onChange={() => false} />
            <span className="checkmark"></span>
            <p className="text-xs sm:text-base">
              Share a few more layouts from pintrest
            </p>
          </label>
          <label className="container">
            <input type="checkbox" onChange={() => false} />
            <span className="checkmark"></span>
            <p className="text-xs sm:text-base">Create AWS account</p>
          </label>
        </motion.div>
      )}

      {size === 'l' && (
        <motion.p
          initial={{
            opacity: 0,
            transform: item ? 'translatey(0px)' : 'translatex(-20px)',
          }}
          animate={{
            opacity: 1,
            transform: item ? 'translatey(0px)' : 'translatex(0px)',
          }}
          transition={{ type: 'tween', stiffness: 100, delay: 0.3 }}
          className="text-[#0B1184] text-[24px] p-2 font-serif"
        >
          We are excited to share a few more layouts from one of our latest
          projects for. to share a few more layouts from one of our latest
          projects for
        </motion.p>
      )}
    </motion.div>
  );
};
