import saveToCol from '../assets/images/saveToCol.jpg';
import saveToColDark from '../assets/images/saveToCol-dark.png';
import cmdk from '../assets/images/cmdk.jpg';
import cmdkDark from '../assets/images/cmdk-dark.png';
import ppl from '../assets/images/ppl.jpg';
import pplDark from '../assets/images/ppl-dark.png';
import socials from '../assets/images/socials.jpg';
import socialsDark from '../assets/images/socials-dark.png';

import Icons from '../components/Icons';
import { useSettings } from '../context/SettingsContext';

const Features = () => {
  const {isDark} = useSettings();
  
  return (
    <div id="Features" className="flex flex-col md:flex-row gap-3 justify-center p-3">
      <div className="relative flex flex-col justify-between p-5 border border-gray-200 rounded-md md:w-1/2">
        <div className="absolute flex flex-row gap-1 items-center top-[20px] left-[20px] rounded-full border border-gray-300 pl-2 pr-4 opacity-50">
          <Icons name="link" />
          nytimes.com
        </div>

        <img src={isDark ? saveToColDark : saveToCol} className="w-full mt-3 md:m-auto" />

        <div>
          <p className="text-lg font-semibold">Save Anything, From Anywhere</p>
          <p className="text-sm opacity-75">
          With COL, effortlessly save items, images, videos, and more from any website directly into your collection. Capture inspiration as you browse.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-col justify-between p-5 border border-gray-200 rounded-md h-full">
          <img src={isDark ? cmdkDark : cmdk} className="w-[600px] m-auto" />

          <div>
            <p className="text-lg font-semibold">Power-Search with ⌘K</p>
            <p className="text-sm opacity-75 max-w-[400px]">
            Instantly find anything in your collections using our power-search. 
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-3 h-1/2">
          <div className="flex flex-col justify-between p-5 border border-gray-200 rounded-md md:w-1/2 ">
            <img src={isDark ? pplDark : ppl} className="w-[300px] m-auto" />

            <div>
              <p className="text-lg font-semibold">Embrace Diversity in Creativity</p>
              <p className="text-sm opacity-75 max-w-[400px]">
              Display your curated collections and connect with a community that appreciates your vision. 
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border border-gray-200 rounded-md md:w-1/2 ">
            <img src={isDark ? socialsDark : socials} className="w-[300px] m-auto" />

            <div>
              <p className="text-lg font-semibold">Discover and Celebrate Creativity</p>
              <p className="text-sm opacity-75 max-w-[400px]">
              COL supports a wide range of media types, enabling you to express your creativity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
