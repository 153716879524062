import React from 'react';
import AI from './AI';
import Collection from './Collection';
import { Example } from './Example';
import { FAQ } from './FAQ';
import Features from './Features';
import Hero from './Hero';
import Multimedia from './Multimedia';

export const Landing = () => {
  return (
    <>
      <Hero  />
      <Multimedia  />
      <Collection  />
      <Features  />
      <AI  />
      <Example  />
      <FAQ  />
    </>
  );
};
