import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export const Spotify = ({ isDark, item, isMobile }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setIsLoaded(true);
    }, 1500);
  }, [isDark]);

  return (
    <motion.div
      initial={{ opacity: 0, transform: "translatey(20px)", filter: "blur(10px)"}}
      animate={{ opacity: 1, transform: "translatey(0px)", filter: "blur(0px)"}}
      transition={{ type: "tween", stiffness: 100 }}
      className="bg-light p-1 shadow-sm rounded-sm border border-gray-300 hover:border-[#ADBAFF] cursor-pointer transition-all duration-300"
    >
      {!isLoaded && <p className="m-auto"></p>}
      <iframe
        className={twMerge(
          isMobile ? "w-[300px] h-[155px]" : "w-[400px] h-[233px]",
          item ? "w-full" : "",
          isMobile && item ? "h-[80px]" : "",
          "rounded-[9px]"
        )}
        src={
          item?.src ??
          "https://open.spotify.com/embed/track/5GUYJTQap5F3RDQiCOJhrS?utm_source=generator" +
            (isDark ? "&theme=0" : "")
        }
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        style={{
          opacity: isLoaded ? "1" : "0",
          transition: "opacity 0.3s",
        }}
        onLoad={() => setIsLoaded(true)}
      ></iframe>
    </motion.div>
  );
};
