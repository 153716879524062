import { twMerge } from "tailwind-merge";
import { Button } from "../Button/Button";
import { useEffect, useState } from "react";
import Icons from "../Icons";
import { motion } from "framer-motion";
import { useSettings } from "../../context/SettingsContext";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Img } from "../Img/Img";

export const Navbar = () => {
  const { currentUser } = useAuth();

  const [activeSection, setActiveSection] = useState<string>("");
  const { isDark, setIsDark } = useSettings();
  const { userId } = useParams();

  useEffect(() => {
    const elem = document.getElementById(activeSection);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [activeSection]);

  // const NAVS =
  //   window.location.pathname === "/"
  //     ? ["Elements", "Collection", "Features", "AI", "Examples", "FAQ"]
  //     : ["Home", "Explore"];
  const NAVS = ["Elements", "Collection", "Features", "AI", "Examples", "FAQ"];

  return (
    <nav
      className={twMerge(
        userId ? "absolute" : "fixed",
        " top-0 left-0 z-20 w-full p-2 bg-light/50 border-b border-gray-200/50 backdrop-blur-md"
      )}
    >
      <div className="flex flex-row justify-between max-w-[1200px] m-auto">
        <div className="flex flex-row gap-2 items-center">
          <Link to="/">
            <motion.span
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 1 }}
              onClick={() => setActiveSection("Hero")}
            >
              <Icons name="logo" className="cursor-pointer w-[16px] h-[16px]" />
            </motion.span>
          </Link>

          <motion.p
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
            className="text-sm font-bold mr-6 cursor-pointer"
            onClick={() => setActiveSection("Hero")}
          >
            COL
          </motion.p>
          <ul className="hidden sm:flex flex-row gap-6">
            {NAVS.map((nav, i) => (
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  stiffness: 200,
                  delay: i / 10,
                }}
                className={twMerge(
                  activeSection === nav ? "!opacity-100" : "opacity-50",
                  "text-sm font-bold hover:!opacity-100 transition duration-300 cursor-pointer select-none"
                )}
                onClick={() => setActiveSection(nav)}
                key={nav}
              >
                {nav}
              </motion.li>
            ))}
          </ul>
        </div>
        <div className="flex flex-row items-center gap-3 ">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            className="flex items-center w-[36px] h-[36px] justify-center border border-gray-300 bg-light/25 hover:bg-gray-200 transition duration-300 rounded-full cursor-pointer select-none"
            onClick={() => setIsDark(!isDark)}
          >
            {isDark ? "☾" : "☀︎"}
          </motion.div>

          {currentUser && (
            <motion.div className="flex items-center w-[36px] h-[36px] justify-center border border-gray-300 bg-light/25 hover:bg-gray-200 transition duration-300 rounded-full cursor-pointer select-none">
              <Icons name="bell" className="w-[16px] h-[16px]" />
            </motion.div>
          )}

          {currentUser ? (
            <Link
              to={`/${currentUser.displayName.split(" ")[0].toLowerCase()}`}
            >
              <Img
                src={currentUser.photoURL}
                className="w-[36px] h-[36px] rounded-full border border-gray-300 hover:border-gray-700 transition-all duration-300 cursor-pointer"
              />
            </Link>
          ) : (
            <>
              <motion.button
                initial={{ opacity: 0, x: 0 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 1.3 }}
                className="bg-gray-500 hover:bg-gray-400 font-bold rounded-sm text-light transition duration-300 px-6 py-2 text-sm"
              >
                Join Waitlist
              </motion.button>
              {/* <Link to="/auth">
                <motion.button
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 0.5, x: 0 }}
                  transition={{ duration: 0.7, delay: 1.3 }}
                  className="opacity-50 hover:!opacity-100 font-bold rounded-sm transition duration-300 text-sm"
                >
                  Login
                </motion.button>
              </Link>{' '} */}
            </>
          )}
        </div>
      </div>
    </nav>
  );
};
