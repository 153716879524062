import { useEffect, useState } from "react";
import TabSelect from "../TabSelect/TabSelect";
import Items from "../Items/Items";
import bg from "../../assets/images/multi-media-bg.png";
import bgDark from "../../assets/images/multi-media-dark-bg.png";
import { useSettings } from "../../context/SettingsContext";
import { twMerge } from "tailwind-merge";

const Elements = () => {
  const [selected, setSelected] = useState("Link");
  const { isDark } = useSettings();
  const [size, setSize] = useState("l");

  const options = [
    {
      title: "Link",
    },
    {
      title: "Image",
    },
    {
      title: "Embeds",
    },
    {
      title: "Colors",
    },
    {
      title: "Text",
    },
  ];

  useEffect(() => {
    if (selected === "Embeds") {
      setSize("m");
    }
  }, [selected]);
  return (
    <div className="border border-gray-300 p-3 rounded-[15px] w-full max-w-[600px] shadow-md">
      <TabSelect
        tabs={options}
        selected={selected}
        onClick={(title) => setSelected(title)}
      />

      <div className="relative flex flex-col items-center justify-between pb-6 md:p-3 h-[450px]">
        <img
          src={isDark ? bgDark : bg}
          className="absolute z-[-1] h-[90%] top-0"
        />

        <span className="m-auto">
          <Items selected={selected} size={size} isDark={isDark} />
        </span>

        <div className="flex flex-row gap-3 items-center">
          {["s", "m", "l"].map((s, i) => (
            <div
              key={s}
              className={twMerge(
                "flex items-center justify-center w-[32px] h-[32px] border border-gray-300 rounded-full bg-light hover:border-[#A89BF2] transition duration-300 cursor-pointer select-none",
                size === s && "border-black hover:border-black bg-black text-white",
                size === s && isDark && "border-white hover:border-white bg-white text-black",
                selected === "Embeds" && s === "l" && "hidden"
              )}
              onClick={() => setSize(s)}
            >
              <p className="text-sm uppercase font-semibold">{i + 1}</p>
            </div>
          ))}
          {/* <div
            className="flex items-center justify-center p-2 border border-gray-300 rounded-[8px] bg-light hover:border-[#A89BF2] transition duration-300 cursor-pointer"
            onClick={() => setSize('s')}
          >
            <svg
              width="58"
              height="12"
              viewBox="0 0 58 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="12"
                height="12"
                rx="3"
                className="transition duration-300"
                fill={size === 's' ? '#A89BF2' : '#D9D9D9'}
              />
              <rect
                opacity="0.4"
                x="15"
                y="3"
                width="43"
                height="5"
                rx="2.5"
                className="transition duration-300"
                fill={size === 's' ? '#A89BF2' : '#D9D9D9'}
              />
            </svg>
          </div> */}
          {/* <div
            className="flex items-center justify-center p-2 border border-gray-300 rounded-[8px] bg-light hover:border-[#A89BF2] transition duration-300 cursor-pointer"
            onClick={() => setSize('m')}
          >
            <svg
              width="57"
              height="22"
              viewBox="0 0 57 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="57"
                height="13"
                rx="3"
                className="transition duration-300"
                fill={size === 'm' ? '#A89BF2' : '#D9D9D9'}
              />
              <rect
                opacity="0.4"
                y="16"
                width="40"
                height="6"
                rx="3"
                className="transition duration-300"
                fill={size === 'm' ? '#A89BF2' : '#D9D9D9'}
              />
            </svg>
          </div> */}
          {/* <div
            className="flex items-center justify-center p-2 border border-gray-300 rounded-[8px] bg-light hover:border-[#A89BF2] transition duration-300 cursor-pointer"
            onClick={() => setSize('l')}
          >
            <svg
              width="52"
              height="25"
              viewBox="0 0 52 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.87"
                width="52"
                height="20"
                rx="3"
                className="transition duration-300"
                fill={size === 'l' ? '#A89BF2' : '#D9D9D9'}
              />
              <rect
                opacity="0.4"
                y="22"
                width="39"
                height="3"
                rx="1.5"
                className="transition duration-300"
                fill={size === 'l' ? '#A89BF2' : '#D9D9D9'}
              />
            </svg>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Elements;
