import { useEffect, useState } from "react";
import { Navbar } from "./components/Navbar/Navbar";
import { ModalContextProvider } from "./context/ModalContext";

import "./App.scss";
import { Logo } from "./components/Logo/Logo";
import { twMerge } from "tailwind-merge";
import { ElementModal } from "./components/ElementModal/ElementModal";

import { Route, Routes } from "react-router-dom";
import Footer from "./pages/Footer";
import { publicRoutes } from "./routes";
import { SettingsContextProvider } from "./context/SettingsContext";
import { AuthProvider } from "./context/AuthContext";

function App() {
  const page = window.location.pathname === "/";

  return (
    <AuthProvider>
      <SettingsContextProvider>
        <ModalContextProvider>
          <>
            <Navbar />
            <main
              className={twMerge(
                "border-r border-l border-gray-200",
                "flex flex-col gap-[100px] sm:gap-[200px] px-3 sm:px-0 max-w-[1200px] justify-center m-auto"
              )}
            >
              <Routes>
                {publicRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </Routes>
              {/* <Logo /> */}
              {/* <ElementModal /> */}
              <Footer />
            </main>
          </>
        </ModalContextProvider>
      </SettingsContextProvider>
    </AuthProvider>
  );
}

export default App;
