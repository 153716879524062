import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import Icons from '../Icons';

const Link = ({ size, item, isMobile }: any) => {
  const linkSizes: any = {
    s: {
      container: {
        height: '50px',
        width: item ? '100%' : isMobile ? '300px' : '320px',
        minWidth: item ? '100%' : isMobile ? '300px' : '320px',
        padding: '5px 10px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      img: {
        height: '24px',
        width: '24px',
        minWidth: '24px',
        borderRadius: '15px',
      },
      text: 'text-sm',
      text2: { display: 'none', opacity: 0, transform: 'translatex(-20px)' },
    },
    m: {
      container: {
        height: '60px',
        width: item ? '100%' : isMobile ? '300px' : '320px',
        minWidth: item ? '100%' : isMobile ? '300px' : '320px',
        padding: '5px 10px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },

      img: {
        height: '40px',
        width: '40px',
        minWidth: '40px',
        borderRadius: '8px',
      },
      text: 'text-sm',
      text2: { display: 'block', opacity: 1, transform: 'translatex(0px)' },
    },
    l: {
      container: {
        height: '215px',
        minWidth: item ? '100%' : isMobile ? '300px' : '320px',
        padding: '5px',
        borderRadius: '10px',
        display: 'block',
      },
      img: {
        height: '150px',
        width: '310px',
        minWidth: '310px',
        borderRadius: '9px',
      },
      text: {},
      text2: { display: 'block', opacity: 1, transform: 'translatex(0px)' },
    },
  };

  return (
    <motion.div
      initial={{
        ...linkSizes[item && isMobile ? 's' : size].container,
        filter: 'blur(10px)',
        opacity: 0,
        transform: 'translatey(20px)',
      }}
      animate={{
        ...linkSizes[item && isMobile ? 's' : size].container,
        filter: 'blur(0px)',
        opacity: 1,
        transform: 'translatey(0px)',
      }}
      transition={{ type: 'tween', stiffness: 100 }}
      className={twMerge(
        !item && 'shadow-sm',
        'relative border border-gray-300 bg-light hover:border-[#ADBAFF] cursor-pointer duration-300'
      )}
      style={{ transitionProperty: 'border' }}
    >
      <div className="relative">
        <motion.img
          initial={{ ...linkSizes[item && isMobile ? 's' : size].img }}
          animate={{ ...linkSizes[item && isMobile ? 's' : size].img }}
          transition={{ type: 'tween', stiffness: 100 }}
          src={
            item?.thumbnail ??
            'https://c.static-nike.com/a/images/w_1920,c_limit/bzl2wmsfh7kgdkufrrjq/image.jpg'
          }
          alt="logo"
          className="overflow-hidden object-cover border border-gray-200"
        />
        {/* <div className="absolute top-[10px] left-[10px] rounded-full border border-gray-300 px-3 opacity-25 text-white">
              nike.com
            </div> */}
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <div className="p-2">
          <p className="text-xs sm:text-base">
            {item?.title ?? 'Nike'}
            {size === 's' && (
              <span className="opacity-25 text-xs">
                {' '}
                · https://www.nike.com/
              </span>
            )}
          </p>
          <motion.p
            initial={{ ...linkSizes[item && isMobile ? 's' : size].text2 }}
            animate={{ ...linkSizes[item && isMobile ? 's' : size].text2 }}
            transition={{ type: 'tween', stiffness: 100 }}
            className="text-xs !opacity-50"
          >
            {item?.desc ?? 'https://www.nike.com/'}
          </motion.p>
        </div>
        <Icons name="box-arrow" className="mr-4" />
      </div>
    </motion.div>
  );
};

export default Link;
