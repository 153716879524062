import { useState } from 'react';
import Items from '../components/Items/Items';
import { COLLECTION, USERS } from '../data';
import { twMerge } from 'tailwind-merge';
import Icons from '../components/Icons';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useSettings } from '../context/SettingsContext';

export const Example = () => {
  const [activeId, setActiveId] = useState(1);
  const {isDark} = useSettings();
  
  return (
    <div id="Examples">
      <div>
        <h2 className="text-[44px] sm:text-[56px] text-center gradient-title mb-3 md:mb-0">
          Create & Explore Collections
        </h2>
        <p className="md:text-[18px] text-center opacity-75 m-auto max-w-[600px]">
        Each collection on your COL profile tells a part of your story. Curate and showcase diverse inspirations that represent you.
        </p>
      </div>

      <div className="hidden sm:flex flex-row gap-6 justify-center items-center mt-[30px]">
        {USERS.map((user) => (
          <div
            className={twMerge(
              activeId === user.id ? 'bg-gray-200' : 'bg-light',
              'flex flex-row gap-3 p-1 px-2 items-center border border-gray-300  w-full max-w-[300px] rounded-sm cursor-pointer hover:bg-gray-200 transition duration-300'
            )}
            onClick={() => setActiveId(user.id)}
            key={user.id}
          >
            <img
              src={user.thumbnail}
              className="w-[40px] h-[40px] rounded-[8px] object-cover"
              alt="user"
            />
            <div>
              <p className="font-bold">
                {user.title}{' '}
                <span className="opacity-50 font-medium">· {user.totalItems}</span>
              </p>
              <div className="flex flex-row gap-1 items-center">
                <img
                  src={user.thumbnail}
                  className="w-[12px] h-[12px] rounded-full object-cover"
                  alt="user"
                />
                <p className="text-sm opacity-50">{user.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex sm:hidden flex-row gap-3 justify-center items-center mt-[30px]">
        <div
          className="h-[54px] flex items-center justify-center p-2 border border-gray-200 rounded-xs transition-opacity duration-300"
          onClick={() => {
            if (activeId <= 0) return setActiveId(2);
            setActiveId(activeId - 1);
          }}
        >
          <Icons name="chevron" className="rotate-90" />
        </div>
        <div
          className={twMerge(
            'flex flex-row gap-3 p-2 px-2 items-center bg-gray-200  w-full max-w-[300px] rounded-sm cursor-pointer hover:bg-gray-200 transition duration-300'
          )}
          onClick={() => setActiveId(USERS[activeId].id)}
        >
          <img
            src={USERS[activeId].thumbnail}
            className="w-[40px] h-[40px] rounded-[8px] object-cover"
            alt="user"
          />
          <div>
            <p className="font-bold">
              {USERS[activeId].title}
              <span className="opacity-50 font-medium">· {USERS[activeId].totalItems}</span>
            </p>
            <div className="flex flex-row gap-1 items-center">
              <img
                src={USERS[activeId].thumbnail}
                className="w-[12px] h-[12px] rounded-full object-cover"
                alt="user"
              />
              <p className="text-sm opacity-50">{USERS[activeId].name}</p>
            </div>
          </div>
        </div>
        <div
          className="h-[54px] flex items-center justify-center p-2 border border-gray-200 rounded-xs transition-opacity duration-300"
          onClick={() => {
            if (activeId >= 2) return setActiveId(0);
            setActiveId(activeId + 1);
          }}
        >
          <Icons name="chevron" className="-rotate-90" />
        </div>
      </div>
      <div className="flex sm:hidden flex-row gap-2 justify-center items-center mt-3">
        <div
          className={twMerge(
            activeId === 0 ? 'bg-blue-700' : 'bg-gray-300',
            'w-[5px] h-[5px] rounded-full transition duration-300'
          )}
        />
        <div
          className={twMerge(
            activeId === 1 ? 'bg-blue-700' : 'bg-gray-300',
            'w-[5px] h-[5px] rounded-full transition duration-300'
          )}
        />
        <div
          className={twMerge(
            activeId === 2 ? 'bg-blue-700' : 'bg-gray-300',
            'w-[5px] h-[5px] rounded-full transition duration-300'
          )}
        />
      </div>

      <div className="max-w-[1200px] overflow-hidden">
        <div className="relative mt-4">
          {/* {COLLECTION[activeId].data.map((item: any, i) => (
            <div
              className="w-full break-inside-avoid p-[7px] mb-4"
              key={'activeId' + item.id}
            >
              <Items selected={item.type} item={item} size="s"  key={'item' + item.id}/>
            </div>
          ))} */}
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry columnsCount={3}>
              {COLLECTION[activeId].data.map((item: any, i) => (
                <div className="m-3" key={'activeId ' + activeId + '-' + i}>
                  <Items
                    selected={item.type}
                    item={item}
                    size="s"
                  />
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>

          <div
            className={twMerge(
              isDark
                ? ' from-[#111111]/0 to-[#111111]/100'
                : ' from-white/0 to-white/100',
              'absolute z-[10] bottom-0 left-0 w-full h-1/2 bg-gradient-to-b'
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};
