import Link from './Link';
import Image from './Image';
import { Video } from './Video';
import { Color } from './Color';
import { Text } from './Text';
import { useResize } from '../../hooks/useResize';
import { motion } from 'framer-motion';
import { Embeds } from './Embeds';

const Items = ({ isDark, selected, size = 'l', item }: any) => {
  const [screenSize] = useResize();

  return (
    <>
      {!selected.includes('list') ? (
        <>
          {selected === 'Link' && (
            <Link size={size} item={item} isMobile={screenSize.width < 600} />
          )}
          {selected === 'Image' && (
            <Image size={size} item={item} isMobile={screenSize.width < 600} />
          )}
          {selected === 'image-gallery' && (
            <Image size={'m'} item={item} isMobile={screenSize.width < 600} />
          )}
          {selected === 'Embeds' && (
            <Embeds
              size={item?.size ?? size}
              item={item}
              isMobile={screenSize.width < 600}
              isDark={isDark}
            />
          )}
          {selected === 'Colors' && (
            <Color
              size={item?.size ?? size}
              item={item}
              isMobile={screenSize.width < 600}
            />
          )}
          {selected === 'Text' && (
            <Text size={size} item={item} isMobile={screenSize.width < 600} />
          )}
          {selected === 'checkbox' && (
            <Text size={'m'} item={item} isMobile={screenSize.width < 600} />
          )}
        </>
      ) : (
        <motion.div
          initial={{
            filter: 'blur(10px)',
            opacity: 0,
            transform: item ? 'translatey(0px)' : 'translatex(-20px)',
          }}
          animate={{
            filter: 'blur(0px)',
            opacity: 1,
            transform: item ? 'translatey(0px)' : 'translatex(0px)',
          }}
          transition={{ type: 'tween', stiffness: 100, delay: 0.3 }}
          className="flex flex-col gap-2 bg-light border border-gray-300 rounded-sm p-2 shadow-sm"
        >
          <p className="text-xs opacity-50 font-bold">List</p>
          {selected === 'list-link' &&
            item.data.map((link: any) => (
              <Link
                size={link?.size ?? size}
                item={link}
                isMobile={screenSize.width < 600}
                key={link.title}
              />
            ))}
          {selected === 'Image' && (
            <Image size={size} item={item} isMobile={screenSize.width < 600} />
          )}
        </motion.div>
      )}
    </>
  );
};

export default Items;
