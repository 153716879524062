import Elements from '../components/Elements/Elements';

const Multimedia = () => {
  return (
    <div
      id="Elements"
      className="flex flex-col md:flex-row gap-6 md:justify-between items-center p-3"
    >
      <div>
        <h2 className="text-[44px] sm:text-[56px] gradient-title mb-3 md:mb-0">
          Multi-Media-Paste
        </h2>
        <p className="md:text-[18px] max-w-[500px] opacity-75">
          Combine music, videos, photos, text and even color codes into your
          collections with COL. Bring diverse media
          together effortlessly, creating collections that truly represent your
          vision.
        </p>
      </div>

      <Elements />
    </div>
  );
};

export default Multimedia;
