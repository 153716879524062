import React, { useState } from 'react';
import { Tweet } from 'react-tweet'
import { Video } from './Video';
import { Spotify } from './Spotify';

export const Embeds = ({ size, item, isMobile, isDark }: any) => {
  return (
    <div className="w-full h-full">
      {size === 's' && <Video item={item} isMobile={isMobile} />}
      {size === 'm' && (
        <Spotify item={item} isMobile={isMobile} isDark={isDark} />
      )}
      {size === 'l' && (
        <div className='h-[200px]'>

       {/* <Tweet id="1688191409898115072" /> */}
        </div>
      )}
    </div>
  );
};
